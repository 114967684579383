<template>
  <div style="background-color: rgb(247, 247, 247); min-height: 100vh">
    <el-card>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div
          style="
            font-size: 20px;
            font-weight: 700;
            width: 90px;
            height: 30px;
            padding-left: 10px;
            border-radius: 1px;
            line-height: 28px;
            border-left: 5px solid #fb6638;
          "
        >
          审核列表
        </div>
        <div>
          <span
            style="
              padding-right: 12px;
              color: #fb6638;
              font-size: 18px;
              line-height: 28px;
            "
            >点击切换审核状态，默认待审核</span
          >
          <el-select
            v-model="status"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.lebal"
              :value="item.status"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </el-card>
    <el-button
      type="primary"
      style="float: right; margin-bottom: 20px"
      @click="refsh"
      >刷新表格</el-button
    >
      <el-dialog title="会员申请处理" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formdata"
        :rules="rules"
        label-width="200px"
      >
      
      <el-form-item label="缴费时间" prop="payDateMsg" >
        <el-date-picker
            v-model="formdata.payDateMsg"
            @change="change789"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="请选择缴费时间"
          />
        </el-form-item>
 <el-form-item label="到期时间" prop="expireDateMsg" >
        <el-date-picker
            v-model="formdata.expireDateMsg"
            @change="change456"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="请选择到期时间"
          />
        </el-form-item>
     
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
        <el-table-column
          align="center"
          prop="nickName"
          label="微信昵称"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="companyName"
          label="公司名称"
          :show-overflow-tooltip="true"
        />
          <el-table-column
          align="center"
          label="服务名称"
          prop="serviceName"
          :show-overflow-tooltip="true"
        >
       
        </el-table-column>
        <el-table-column
          align="center"
          prop="managerName"
          label="联系人"
          :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column
          align="center"
          prop="account"
          label="账号（手机号）"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          label="状态"
          prop="status"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.status | zt }}
          </template>
        </el-table-column>


        
        <el-table-column
          align="center"
          label="申请时间"
          prop="createTime"
          :show-overflow-tooltip="true"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | getLocalTime }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button
              type="success"
              :disabled="scope.row.status == 1"
              size="small"
              @click="agree(scope.row)"
            >
              通过
            </el-button>
            <el-button
              type="primary"
              :disabled="(scope.row.status == 1) || (scope.row.status == 2)"
              size="small"
              @click="refuse(scope.row)"
            >
              拒绝
            </el-button>
            <el-button
              type="danger"
              size="small"
              @click="delCompanyApply(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getCompanyApplyList"
      />
    </div>

  </div>
</template>

<script>
import {
  getCompanyApplyList,
  dealCompanyApply,
  delCompanyApply,
} from "../../request/http";
export default {
  data() {
    return {
      formdata: {
        payDateMsg: "",
        expireDateMsg:"",
      },

      applyId: "",
      showDialog: false,
      list: "",
      total: 0,
      queryParams: {
        page: 1,
        pageSize: 10,
      },

      status: 0,
      options: [
        {
          lebal: "全部",
        },
        {
          lebal: "待审核",
          status: 0,
        },

        {
          lebal: "通过",
          status: 1,
        },
        {
          lebal: "拒绝",
          status: 2,
        },
      ],
       rules: {
       expireDateMsg:[{required:true,message:'到期时间不能为空',trigger:'blur'}],
      },
     
    };
  },
  created() {
    this.getCompanyApplyList(0);
  },
  mounted() {},
  methods: {
      //选择缴费时间
    change789(val) {
      this.formdata.payDateMsg = val;
      console.log(this.formdata.payDateMsg);
    },
     change456(val) {
      this.formdata.expireDateMsg = val;
      console.log(this.formdata.expireDateMsg);
    },
    refsh() {
      location.reload();
    },

    selectChange(value) {
      console.log(value);
      this.status = value;
      this.queryParams.page=1
   
      //  console.log(this.status);
      // getCompanyApplyList({
      //     page:this.queryParams.page,
      //     pageSize:10,
      //     status: this.status,
      //   }).then((res) => {
      //     this.list = res.data;
      //     this.total = res.count;
      //   });
      this.getCompanyApplyList(this.status);
    },
    
    getCompanyApplyList(value) {
      console.log(value);
      if (this.status == 0) {
        getCompanyApplyList({
          page:this.queryParams.page,
          pageSize:10,
          status: this.status,
        }).then((res) => {
          this.list = res.data;
          this.total = res.count;
        });
      } else if (this.status == 1) {
        getCompanyApplyList({
          page:this.queryParams.page,
          pageSize:10,
          status: 1,
        }).then((res) => {
          this.list = res.data;
          this.total = res.count;
        });
      } else if (this.status == 2) {
        getCompanyApplyList({
          page:this.queryParams.page,
          pageSize:10,
          status: 2,
        }).then((res) => {
          this.list = res.data;
          this.total = res.count;
        });
      } else {
        getCompanyApplyList({
          page:this.queryParams.page,
          pageSize:10,
        }).then((res) => {
          this.list = res.data;
          this.total = res.count;
        });
      }
    },
 
   
    //弹窗取消函数
    btnCancel() {
      this.formdata.payDateMsg = "";
      this.formdata.expireDateMsg=""
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },
   async btnOK(){
        try {
        await this.$confirm("确定要通过申请吗");
        console.log(this.applyId);
      let{code,msg} = await dealCompanyApply({
          applyId: this.applyId,
          status: 1,
          payDateMsg:this.formdata.payDateMsg,
          expireDateMsg:this.formdata.expireDateMsg
        });
        if(code==0){
          this.$message.success("已通过");
        }
        else {
    this.$message.warning(msg);
        }
      
      } catch (error) {
        console.log(error);
      }
      this.getCompanyApplyList(this.status);
        this.showDialog = false;
    },
   async agree(row) {
     this.applyId =row.applyId
     this.showDialog=true
    },
    async refuse(row) {
      try {
        await this.$confirm("确定要拒绝申请吗");
        console.log(row);
        await dealCompanyApply({
          applyId: row.applyId,
          status: 2,
        });

        this.$message.success("已拒绝");
      } catch (error) {
        console.log(error);
      }
      this.getCompanyApplyList(this.status);
    },
    async delCompanyApply(row) {
      try {
        await this.$confirm("确定要删除该数据吗");
        console.log(row);
        await delCompanyApply({
          applyId: row.applyId,
        });
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        );
        this.queryParams.page =
          this.queryParams.page > totalPage ? totalPage : this.queryParams.page;
        this.queryParams.page =
          this.queryParams.page < 1 ? 1 : this.queryParams.page;
        this.getCompanyApplyList(this.status);
        this.$message.success("删除成功");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
/deep/ .el-select {
  width: 450px;
}
/deep/ .el-card {
  border-radius: 10px;
  margin-bottom: 30px;
}
.minecard {
  box-sizing: border-box;
  width: 320px;
  height: 115px !important;
}
.minecard_item_title {
  color: #909399;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}
.minecard_item_text {
  padding-top: 10px;
  color: #303133;
  font-size: 33px;
  line-height: 1;
  font-weight: 700;
}

/deep/ .minecard .el-card__body {
  box-sizing: border-box;
  height: 100%;
}
</style>
